import React from 'react';
import { inject, observer } from 'mobx-react';
import { Field, renderField, renderSelect, renderToggle } from '../../../../utils/formFieldHelper';
import Form from '../../../Settings/Form/Form';
import Section from '../../../Section/Section';
import { dailyUnitTypes } from '../../../../utils/constants';
import { StoresEnum } from '../../../../stores';

const AutoPollForm = ({ channelStore: { channel, readOnly, updateChannel } }) => {
  const initUnitType = dailyUnitTypes.find(type => type.id === channel.dailyUnit?.unitType);

  const renderFields = () => (
    <>
      <Field
        name="dailyUnit.unitType"
        label="Unit type"
        defaultValue={initUnitType?.name}
        component={renderSelect(dailyUnitTypes)}
        required
        readOnly={readOnly}
      />
      <Field name="dailyUnit.enabled" type="checkbox" component={renderToggle} label="Enale Auto-Create Daily Unit" readOnly={readOnly} />
      <Field name="dailyUnit.siteSection" type="text" component={renderField} label="Target or path domain" readOnly={readOnly} allowEmpty />
      <Field name="dailyUnit.firstArticleSelector" type="text" component={renderField} label="Query selector of first article" readOnly={readOnly} />
    </>
  );

  return (
    <Section title="INSTANT DAILY UNIT">
      <Form
        initialValues={channel}
        fields={renderFields()}
        onSubmit={updateChannel}
        hideCancelButton
        hideApproveButton={readOnly}
      />
    </Section>
  );
};

export default inject(StoresEnum.CHANNEL)(observer(AutoPollForm));
