import React from 'react';

import AdminGeneralForm from './AdminGeneralForm';
import OnUnitAndPlaylistForm from './OnUnitAndPlaylistForm';
import AdsSettingsForm from './AdsSettingsForm';
import AdsBilling from './AdsBilling';
import StoryStripForm from './StoryStripForm';
// eslint-disable-next-line import/no-named-as-default
import BrandedStoryStripForm from './BrandedStoryStrip/BrandedStoryStrip';
import TrackingSettingsForm from './TrackingSettingsForm';
import AutoDailyUnitForm from './AutoDailyUnitForm';
import AutoPollForm from './AutoPollForm';
import AutoQuizForm from './AutoQuizForm';

const ChannelGeneral = () => (
  <>
    <AdminGeneralForm />

    <OnUnitAndPlaylistForm />

    <BrandedStoryStripForm />

    <AutoDailyUnitForm />

    <StoryStripForm />

    <AutoPollForm />

    <AutoQuizForm />

    <AdsSettingsForm />

    <AdsBilling />

    <TrackingSettingsForm />
  </>
);

export default ChannelGeneral;
