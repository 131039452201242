module.exports = {
  adsetPublicUrl: 'https://adset.apester.com',
  analyticsBaseUrl: 'https://analytics.apester.com',
  authBaseUrl: 'https://auth.apester.com',
  campaignBaseUrl: 'https://campaign.apester.com',
  campaignApiPublicUrl: 'https://campaign-api.apester.com',
  adsetApiPublicUrl: '___ADSET_API_PUBLIC_URL___',
  consoleBaseUrl: 'https://console.apester.com',
  vreminiUrl: 'https://vremini.apester.com',
  editorBaseUrl: 'https://app.apester.com',
  eventsPublicUrl: 'https://events.apester.com',
  imagesNewUrl: 'https://img.apester.com',
  imagesUrl: 'https://img.apester.com',
  interactionBaseUrl: 'https://interaction.apester.com',
  jobsUrl: 'https://jobs-api.apester.com',
  paymentsUrl: 'https://payments.apester.com/',
  planApiUrl: 'https://plans-api.apester.com',
  playerBaseUrl: 'https://renderer.apester.com/v2/',
  portalUrl: 'https://portal.apester.com',
  staticBucket: 'https://static.apester.com/',
  stripePK: 'pk_live_YL6LSULaHEGhq0GpzvPoulFe00SJvfnPkA',
  usersBaseUrl: 'https://users.apester.com',
  wixIntegrationUrl: 'https://wix-integration.apester.com',
  webhooksBaseUrl: 'https://webhooks.apester.com',
  release: '2.5.41',
};
